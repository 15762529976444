"use client";

import React, { ComponentProps, memo, useCallback } from "react";
import styled from "styled-components";

import { ActivityIndicator } from "components/ui/ActivityIndicator";
import { spacing } from "libs/styles/variables";
import { Button } from "..";

type Props = ComponentProps<typeof Button> & {
  working: boolean;
  "data-confirm"?: string;
};

export const ActivityButton = memo(function ActivityButton({
  working,
  children,
  disabled,
  buttonTheme: theme,
  "data-confirm": dataConfirm,
  onClick,
  ...otherProps
}: Props) {
  const useConfirmClick = useCallback(
    (e: React.MouseEvent) => {
      if (dataConfirm && !window.confirm(dataConfirm)) {
        e.preventDefault();
        return;
      }
      if (!onClick) return;

      onClick(e);
    },
    [dataConfirm, onClick]
  );

  return (
    <Button
      disabled={disabled || working}
      buttonTheme={theme}
      onClick={useConfirmClick}
      {...otherProps}
    >
      {working && <Indicator size={20} />}
      {children}
    </Button>
  );
});

Button.displayName = "ActivityButton";

const Indicator = styled(ActivityIndicator)`
  left: ${spacing.xs};
  margin: 0 !important;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`;
