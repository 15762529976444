"use client";

import { H1 } from "components/ui/H";
import { Children, Fragment, memo, ReactNode } from "react";
import classNames from "classnames";

type spacingSize = "sm" | "md" | "lg" | "xl" | "xxl";

type Props = {
  children?: ReactNode;
  spacing?: spacingSize;
};

const getSpacingClass = (spacing: spacingSize, isFirst: boolean): string => {
  if (isFirst) return "";
  switch (spacing) {
    case "sm":
      return "pt-sm";
    case "md":
      return "pt-md";
    case "lg":
      return "pt-lg";
    case "xl":
      return "pt-xl";
    case "xxl":
      return "pt-xxl";
    default:
      return "pt-md";
  }
};

export function Stack({ children, spacing = "md" }: Props) {
  return (
    <div>
      {Children.map(children, (child, i) => {
        if (child !== null && child !== undefined) {
          return (
            <div key={i} className={getSpacingClass(spacing, i === 0)}>
              {child}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

export const Row = memo(function Row({
  title,
  children,
}: {
  children: ReactNode;
  title: string;
}) {
  return (
    <Fragment>
      <H1 className="mb-xs">{title}</H1>
      {children}
    </Fragment>
  );
});

export const Pair = ({
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
  [key: string]: any;
}) => (
  <div className={classNames("flex w-188 mt-sm mb-md", className)} {...props}>
    {children}
  </div>
);

export const Header = ({
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
  [key: string]: any;
}) => (
  <div
    className={classNames(
      "text-md leading-[1.25] text-black-56 font-default normal-case text-justify w-52 flex-shrink-0",
      className
    )}
    {...props}
  >
    {children}
  </div>
);

export const Value = ({
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
  [key: string]: any;
}) => (
  <div
    className={classNames(
      "text-sm leading-[1.25] text-black-56 font-default normal-case text-justify",
      className
    )}
    {...props}
  >
    {children}
  </div>
);
