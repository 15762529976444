"use client";

import classNames from "classnames";
import { ComponentProps, ReactNode } from "react";
import styled, { css } from "styled-components";

import { spacing } from "libs/styles/variables";
import Link from "next/link";

type ButtonTheme =
  | "default"
  | "primary"
  | "secondary"
  | "danger"
  | "white"
  | "black12";
type ButtonSize = "sm" | "md" | "lg";

type ButtonProps = {
  buttonTheme?: ButtonTheme;
  size?: ButtonSize;
  block?: boolean;
  className?: string;
  children: ReactNode;
};

type Props = ComponentProps<typeof Link> & ButtonProps;

const buttonThemeClasses: Record<ButtonTheme, string> = {
  default:
    "bg-primaryBaseLight border-primaryBaseLight text-black-87 hover:border-black-04 focus:border-primaryDark1 active:border-black-12",
  primary:
    "bg-primary border-primary text-white-100 hover:bg-primaryDark1 hover:border-primaryDark1 focus:border-primaryDark1 active:bg-primaryDark2 active:border-primaryDark2",
  secondary:
    "bg-secondary border-secondary text-white-100 hover:bg-secondaryDark1 hover:border-secondaryDark1 focus:border-secondaryDark2 active:bg-secondaryDark2 active:border-secondaryDark2",
  danger:
    "bg-alert border-alert text-white-100 hover:bg-alertDark1 hover:border-alertDark1 focus:border-alertDark1 active:bg-alertDark2 active:border-alertDark2",
  white:
    "bg-white-100 border-white-100 text-black-87 hover:bg-white-87 hover:border-white-87 focus:border-primaryDark1 active:bg-white-56 active:border-white-56",
  black12:
    "bg-transparent border-black-12 text-black-38 hover:bg-black-12 hover:border-black-12 focus:border-black-12 active:bg-black-12 active:border-black-12",
};

const sizeClasses: Record<ButtonSize, string> = {
  sm: "rounded-full text-xs h-7.5 py-0 px-md",
  md: "rounded-md text-sm h-12 py-0 px-xxl",
  lg: "rounded-md text-sm h-14 py-0 px-xxl",
};

export const LinkButton = ({
  block,
  children,
  className,
  size = "md",
  buttonTheme = "default",
  ...otherProps
}: Props) => {
  return (
    <Base
      className={classNames(
        className,
        [
          "items-center",
          "border-2 border-solid",
          "box-border",
          "cursor-pointer",
          "font-medium",
          "justify-center",
          "outline-0",
          "relative",
          "text-center",
          "no-underline",
          "hover:shadow-sm",
          "active:transition-none",
          block ? ["flex w-full"] : "inline-flex",
        ],
        buttonThemeClasses[buttonTheme],
        sizeClasses[size]
      )}
      $size={size}
      {...otherProps}
    >
      {children}
    </Base>
  );
};

LinkButton.displayName = "LinkButton";

type BaseProps = {
  $size?: ButtonSize;
} & Omit<Props, "$size">;

const Base = styled(Link)<BaseProps>`
  ${({ $size }) => getSizeStyles($size)}
`;

const getSizeStyles = (size?: ButtonSize) => {
  switch (size) {
    case "sm":
      return css`
        > svg {
          height: 18px;
          margin-left: -${spacing.xxs};
          margin-right: ${spacing.xs};
          width: 18px;

          &:only-child {
            margin: 0 -${spacing.lg};
          }
        }

        > span + svg {
          margin-left: ${spacing.xs};
          margin-right: -${spacing.xxs};
        }
      `;
    case "md":
      return css`
        > svg {
          height: 20px;
          margin-left: -${spacing.md};
          margin-right: ${spacing.sm};
          width: 20px;

          &:only-child {
            margin: 0 -${spacing.lg};
          }
        }

        > span + svg {
          margin-left: ${spacing.sm};
          margin-right: -${spacing.md};
        }
      `;
    case "lg":
      return css`
        > svg {
          height: 24px;
          margin-left: -${spacing.sm};
          margin-right: ${spacing.sm};
          width: 24px;

          &:only-child {
            margin: 0 -${spacing.lg};
          }
        }

        > span + svg {
          margin-left: ${spacing.sm};
          margin-right: -${spacing.sm};
        }
      `;
  }
};
