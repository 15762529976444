import classNames from "classnames";
import { ComponentProps, memo } from "react";
import { Status } from ".";

type Props = Omit<ComponentProps<typeof Status>, "theme" | "children"> & {
  type: "require" | "optional";
};

const typeToTheme = {
  require: "danger",
  optional: "primary",
} as const;
const typeToChildren = {
  require: "必須",
  optional: "任意",
} as const;

export const RequirementStatus = memo(function RequirementStatus({
  className,
  type,
  ...otherProps
}: Props) {
  return (
    <Status
      className={classNames([className, "min-w-10", "px-2", "py-1"])}
      statusTheme={typeToTheme[type]}
      {...otherProps}
    >
      {typeToChildren[type]}
    </Status>
  );
});
