import classNames from "classnames";
import Link, { LinkProps } from "next/link";
import { AnchorHTMLAttributes, ComponentProps } from "react";
type CustomProps = {
  theme: "primary" | "black87";
  // テーマと異なりサイズは、周辺のテキストのサイズに自動的にあわせるかもしれないので、オプショナルとしておく
  size?: "xs" | "sm" | "md" | "lg" | "xl";
};

type LinkComponentProps<T extends "a" | "Link"> = (T extends "a"
  ? { as: "a" } & AnchorHTMLAttributes<HTMLAnchorElement>
  : { as: "Link" } & ComponentProps<typeof Link> & {
        children: React.ReactNode;
      }) &
  CustomProps;

export const TextLink = <T extends "a" | "Link">({
  as,
  theme,
  size,
  ...props
}: LinkComponentProps<T>) => {
  let c = [
    "no-underline",
    "hover:text-primaryDark2",
    "active:text-primaryDark2",
  ];

  if (theme === "primary") {
    c.push("text-primary", "visited:text-primary");
  } else if (theme === "black87") {
    c.push("text-black-87", "visited:text-black-87");
  }

  if (size === "xs") {
    c.push("text-xs");
  } else if (size === "sm") {
    c.push("text-sm");
  } else if (size === "md") {
    c.push("text-md");
  } else if (size === "lg") {
    c.push("text-lg");
  } else if (size === "xl") {
    c.push("text-xl");
  }

  if (as === "a") {
    const { href, ...rest } = props as AnchorHTMLAttributes<HTMLAnchorElement>;
    return <a href={href} className={classNames(c)} {...rest} />;
  } else {
    const { href, children, ...rest } = props as LinkProps & {
      children: React.ReactNode;
    };
    return (
      <Link href={href} {...rest} className={classNames(c)}>
        {children}
      </Link>
    );
  }
};
