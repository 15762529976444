"use client";

import { ActivityButton } from "components/ui/Button/ActivityButton";
import { Card } from "components/ui/Card";
import { CardSection } from "components/ui/Card/CardSection";
import { ErrorBlock } from "components/ui/ErrorBlock";
import { H2 } from "components/ui/H";
import { Stack } from "components/ui/Stack";
import { useRouter } from "next/navigation";
import { Fragment, useCallback } from "react";
import { useLoginForm } from "../hooks/useLoginForm";
import { EmailInputField } from "./EmailInputField";
import { PasswordInputField } from "./PasswordInputField";
import { RememberMeInputField } from "./RememberMeInputField";

export const LoginFormCard = () => {
  const router = useRouter();
  const handleLoginSucceeded = useCallback(() => {
    router.push("/delivery_requests");
  }, [router]);
  const handleAccountLocked = useCallback(() => {
    router.push("/account_locked");
  }, [router]);

  const {
    form,
    formFullFilled,
    errorMessage,
    submitStatus,
    recaptchaRef,
    handleSubmit,
  } = useLoginForm({
    onLoginSucceeded: handleLoginSucceeded,
    onAccountLocked: handleAccountLocked,
  });

  const {
    register,
    formState: { errors },
  } = form;

  return (
    <div className="mr-auto ml-auto text-center min-w-80">
      <form onSubmit={handleSubmit}>
        <Card>
          <CardSection>
            <Stack spacing="xl">
              <H2>ログイン</H2>
              {errorMessage && <ErrorBlock>{errorMessage}</ErrorBlock>}
              <Fragment>
                <EmailInputField errors={errors} register={register} />
                <PasswordInputField errors={errors} register={register} />
              </Fragment>
              <RememberMeInputField register={register} />
              <div ref={recaptchaRef} />
              <ActivityButton
                block={true}
                disabled={!formFullFilled}
                size="md"
                buttonTheme="primary"
                type="submit"
                working={submitStatus.running}
              >
                OK
              </ActivityButton>
            </Stack>
          </CardSection>
        </Card>
      </form>
    </div>
  );
};
