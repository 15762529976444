import { InputField } from "components/ui/InputField";
import { PasswordField } from "components/ui/TextField/PasswordField";
import { TextLink } from "components/ui/TextLink";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { LoginFormValues } from "../types";

type Props = {
  errors: FieldErrors<LoginFormValues>;
  register: UseFormRegister<LoginFormValues>;
};

export const PasswordInputField = ({ errors, register }: Props) => {
  return (
    <InputField
      error={errors.password}
      rightItem={
        <div className="flex justify-end">
          <TextLink
            as="a"
            theme="primary"
            size="sm"
            href="https://api.crewexp.com/contact-form/client"
            rel="noopener noreferrer"
            target="_blank"
          >
            パスワードを忘れた場合
          </TextLink>
        </div>
      }
      size="xs"
      title="パスワード"
      block
    >
      <PasswordField
        block
        error={errors.password}
        {...register("password", {
          required: true,
          setValueAs: (v) => v.trim(),
        })}
        placeholder="・・・・・・・・"
      />
    </InputField>
  );
};
