"use client";

import { Colors } from "components/colors";
import { Font } from "components/font";
import { RequirementStatus } from "components/ui/Status/RequirementStatus";
import { getErrorMessage } from "libs/react-hook-form";
import { palette } from "libs/styles/colors";
import { fontSize, spacing } from "libs/styles/variables";
import { ComponentProps, ReactNode, memo } from "react";
import { FieldError, Merge } from "react-hook-form";
import styled, { css } from "styled-components";

type InputFieldSize = "xs" | "sm" | "md";

type Props = {
  title?: ReactNode;
  requirement?: ComponentProps<typeof RequirementStatus>["type"];
  rightItem?: ReactNode;
  children: ReactNode;
  size?: InputFieldSize;
  error?: Merge<FieldError, (FieldError | undefined)[]>;
  errorMessageId?: string;
  block?: boolean;
};

export const InputField = memo(function InputField({
  title,
  requirement,
  rightItem,
  children,
  error,
  errorMessageId,
  size = "md",
  block = false,
}: Props) {
  const errorMessage = getErrorMessage(error);

  return (
    <Base size={size} $block={!!block}>
      <Header>
        {title && <Title size={size}>{title}</Title>}
        {requirement && <Status type={requirement} />}
        <RightItem>
          {rightItem}
          {errorMessage && (
            <ErrorMessage id={errorMessageId} size={size}>
              {errorMessage}
            </ErrorMessage>
          )}
        </RightItem>
      </Header>
      <Body>{children}</Body>
    </Base>
  );
});

export const Base = styled.dl<{ size?: InputFieldSize; $block: boolean }>`
  margin: 0;
  padding: 0;
  ${({ $block }) => $block && "display: block; width: 100%;"};

  ${({ size }) => {
    switch (size) {
      case "xs":
        return css`
          & + & {
            margin-top: ${spacing.md};
          }

          & & + & {
            margin-top: ${spacing.sm};
          }
        `;
      case "sm":
        return css`
          & + & {
            margin-top: ${spacing.lg};
          }

          & & + & {
            margin-top: ${spacing.md};
          }
        `;
      case "md":
        return css`
          & + & {
            margin-top: ${spacing.xl};
          }

          & & + & {
            margin-top: ${spacing.md};
          }
        `;
    }
  }}
`;

const Header = styled.dt`
  align-items: center;
  display: flex;
`;

const Title = styled.strong<{ size: InputFieldSize }>`
  flex-shrink: 0;
  font-weight: 500;

  ${({ size }) => {
    switch (size) {
      case "xs":
        return css`
          font-size: ${fontSize.sm};
          font-weight: normal ${Base} ${Base} & {
            color: ${Colors.black56};
            font-size: ${Font.fontSizeXs};
            font-weight: normal;
          }
        `;
      case "sm":
        return css`
          font-size: ${fontSize.md};

          ${Base} ${Base} & {
            color: ${Colors.black56};
            font-size: ${Font.fontSizeXs};
          }
        `;
      case "md":
        return css`
          font-size: ${fontSize.lg};

          ${Base} ${Base} & {
            color: ${Colors.black56};
            font-size: ${Font.fontSizeSm};
          }
        `;
    }
  }}
`;

const Status = styled(RequirementStatus)`
  flex-shrink: 0;
  margin-left: ${spacing.sm};
`;

const RightItem = styled.div`
  flex: 1;
  margin-left: ${spacing.sm};
  min-width: 0;
  overflow: visible;
  text-align: left;
`;

const ErrorMessage = styled.p<{ size: InputFieldSize }>`
  color: ${palette.red.default};
  margin: 0;
  text-align: left;

  ${({ size }) => {
    switch (size) {
      case "xs":
      case "sm":
        return css`
          font-size: ${fontSize.xs};
        `;
      case "md":
        return css`
          font-size: ${fontSize.sm};
        `;
    }
  }}
`;

const Body = styled.dd`
  margin: ${spacing.sm} 0 0;
  padding: 0;
`;
