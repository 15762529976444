import classNames from "classnames";
import { ReactNode } from "react";

type themeList =
  | "bare"
  | "primary"
  | "primary_hollow"
  | "secondary"
  | "secondary_hollow"
  | "danger"
  | "danger_hollow"
  | "muted";

type Props = {
  statusTheme?: themeList;
  className?: string;
  children: ReactNode;
};

export const Status = ({
  statusTheme: theme = "bare",
  className,
  children,
}: Props) => {
  return (
    <span
      className={classNames(
        "border",
        "border-solid",
        "box-border",
        "rounded-sm",
        "inline-block",
        "text-xs",
        "leading-none",
        "text-center",
        "p-1.5",
        "min-w-19",
        theme === "bare"
          ? ["text-black-87", "bg-white-100", "border-white-100"]
          : theme === "primary"
          ? ["text-primary", "bg-primaryLight2", "border-primary"]
          : theme === "primary_hollow"
          ? ["text-primary", "bg-white-100", "border-primary"]
          : theme === "secondary"
          ? ["text-secondary", "bg-secondaryLight2", "border-secondary"]
          : theme === "secondary_hollow"
          ? ["text-secondary", "bg-white-100", "border-secondary"]
          : theme === "danger"
          ? ["text-alert", "bg-alertLight2", "border-alert"]
          : theme === "danger_hollow"
          ? ["text-alert", "bg-white-100", "border-alert"]
          : theme === "muted"
          ? ["text-black-87", "bg-black-12", "border-black-87"]
          : [],
        className
      )}
    >
      {children}
    </span>
  );
};
