import { InputField } from "components/ui/InputField";
import { TextField } from "components/ui/TextField";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { LoginFormValues } from "../types";

type Props = {
  errors: FieldErrors<LoginFormValues>;
  register: UseFormRegister<LoginFormValues>;
};

export const EmailInputField = ({ errors, register }: Props) => {
  return (
    <InputField error={errors.email} size="xs" title="メールアドレス" block>
      <TextField
        block
        error={errors.email}
        {...register("email", {
          required: true,
          setValueAs: (v) => v.trim(),
        })}
        placeholder="deliveryx@example.com"
        type="email"
      />
    </InputField>
  );
};
