export const FEATURES = {
  // built-in flags
  TEST: "TEST",
  DEBUG: "DEBUG",
  TmsCancel: "TMS_CANCEL",
  ShippingLabel: "SHIPPING_LABEL",
} as const;
type FEATURES = (typeof FEATURES)[keyof typeof FEATURES];

export function isEnableFeature(key: FEATURES): boolean {
  if (!process.env.NEXT_PUBLIC_ENABLE_FEATURES) return false;
  for (const v of process.env.NEXT_PUBLIC_ENABLE_FEATURES.split(",")) {
    if (v === key) return true;
  }

  return false;
}
