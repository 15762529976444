import { CheckBoxLabel } from "components/ui/CheckBox/CheckBoxLabel";
import { UseFormRegister } from "react-hook-form";
import { LoginFormValues } from "../types";

type Props = {
  register: UseFormRegister<LoginFormValues>;
};

export const RememberMeInputField = ({ register }: Props) => {
  return (
    <CheckBoxLabel {...register("remember_me", {})} value="1" block>
      ログイン状態を保持
    </CheckBoxLabel>
  );
};
