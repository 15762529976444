import classNames from "classnames";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
};

export const H1 = ({ children, className }: Props) => {
  return (
    <h1 className={classNames("m-0 p-0 text-xl font-medium", className)}>
      {children}
    </h1>
  );
};

export const H2 = ({ children, className }: Props) => {
  return (
    <h2 className={classNames("m-0 p-0 text-lg font-medium", className)}>
      {children}
    </h2>
  );
};

export const H3 = ({ children, className }: Props) => {
  return (
    <h3 className={classNames("m-0 p-0 text-base font-medium", className)}>
      {children}
    </h3>
  );
};

export const H4 = ({ children, className }: Props) => {
  return (
    <h4 className={classNames("m-0 p-0 text-sm font-normal", className)}>
      {children}
    </h4>
  );
};
