"use client";

import isChromatic from "chromatic/isChromatic";
import classNames from "classnames";
import { useEffect, useState } from "react";

const M_PI2 = Math.PI * 2;
const M_PI4 = Math.PI * 4;

type ThemeList = "default" | "primary" | "inverse";

type Props = {
  size: number;
  theme?: ThemeList;
  className?: string;
};

function ease(x: number): number {
  let n = x;
  if (x < 0) {
    n = (x % 1) + 1;
  } else if (x > 1) {
    n = x % 1;
  }

  return (
    ((((19.2 * n - 240 / 5) * n + 232 / 5) * n - 108 / 5) * n + 25 / 5) * n
  );
}
const startTime = Date.now();

const drawD = (delta: number, size: number, stroke: number) => {
  const half = size / 2;
  const step = (delta % 1400) / 1400;
  const base = delta / 500;
  const theta0 = ease(step) * M_PI2 + base;
  const theta1 = ease(step + 0.5) * M_PI2 + base;
  const angleRadian = (theta1 - theta0 + M_PI4) % M_PI2;
  const r = half - stroke;
  const x0 = half + Math.sin(theta0) * r;
  const y0 = half - Math.cos(theta0) * r;
  const x1 = half + Math.sin(theta1) * r;
  const y1 = half - Math.cos(theta1) * r;

  return `M ${x0},${y0} A ${r} ${r} 0 ${
    angleRadian > Math.PI ? 1 : 0
  } 1 ${x1},${y1}`;
};

export const ActivityIndicator = ({
  className,
  size,
  theme = "default",
}: Props) => {
  const [ref, setRef] = useState<SVGPathElement | null>(null);
  useEffect(() => {
    if (!ref) return;
    const localRef = ref;
    const stroke = size * 0.1;
    localRef.setAttributeNS(null, "stroke-width", stroke.toString());

    // Chromaticでのスナップショットテストのために、アニメーションを無効化する
    if (isChromatic()) {
      const d = drawD(4 * 1000, size, stroke);
      localRef.setAttributeNS(null, "d", d);
      return;
    }

    function update() {
      const delta = Date.now() - startTime;
      const d = drawD(delta, size, stroke);
      localRef.setAttributeNS(null, "d", d);
      timer = requestAnimationFrame(update);
    }
    let timer = requestAnimationFrame(update);

    return () => cancelAnimationFrame(timer);
  }, [size, ref]);

  return (
    <svg className={className} height={size} width={size}>
      <path
        className={classNames([
          "fill-transparent",
          theme === "default"
            ? "stroke-black-38"
            : theme === "primary"
            ? "stroke-primary"
            : theme === "inverse"
            ? "stroke-white-100"
            : null,
        ])}
        strokeLinecap="round"
        ref={setRef}
      />
    </svg>
  );
};
